<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Registries from "@/components/wallet/registries/index";
import Policies from "@/components/wallet/policies/index";
import AgencyContacts from "@/components/wallet/agency-contacts/index";
import Claims from "@/components/wallet/claims/index";
import RegistryGroups from "@/components/wallet/registry-groups/index";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.PFOLIO",
      tabs: [
        { name: "Registries", text: "Clienti" },
        { name: "RegistryGroups", text: "Gruppi" },
        { name: "Policies", text: "Polizze" },
        { name: "Claims", text: "Sinistri" },
        { name: "AgencyContacts", text: "Contatti" },
      ],
      tabsGroup: "Management",
      bcs: [
        {
          text: "Portafoglio",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Portafoglio",
          path: "",
          level: 1,
        },
        {
          text: "Gestione Portafoglio",
          path: "",
          level: 2,
        },
        {
          text: "Clienti",
          path: "module.PFOLIO",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3,
      selected: "Registries",
      tabIndex: 0,
    };
  },
  components: {
    Registries,
    Policies,
    Claims,
    RegistryGroups,
    AgencyContacts,
  },
};
</script>
